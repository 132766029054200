import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import PageNotFound from '../views/PageNotFound.vue'
import Jobs from '../views/job/Jobs.vue'
import JobDetial from '../views/job/JobDetial.vue'
import StudenID from '../views/job/StudenID.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    // redirect:'https://cadt.edu.kh/',
    // component: HomeView,
    beforeEnter: () => {
      window.location.href = 'https://cadt.edu.kh/';
    }
  }, 
  {
    path: '/IDT',
    name: 'IDT',
    // redirect:'https://idt.edu.kh/',
    // component: HomeView,
    beforeEnter: () => {
      window.location.href = 'https://idt.edu.kh/';
 
    }
   
  },
  {
    path: '/IDT/:id',
    name: 'StudnetID',
    component: StudenID,
    props:true,
    meta: {
      title: 'Student Info',
    }
  },
  {
    path: '/job/:id',
    name: 'JobDetial',
    component: JobDetial,
    props:true,
    meta: {
      reload: true,
      title: 'STUDENT INFOMATION'
    },
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  }, {
    path: '/:catchAll(.*)',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
