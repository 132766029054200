import { ref } from "vue"; 
import config from '../../config'
const getStudents = (id) => {
    const student = ref([]); 
    const error = ref(null);  
    const load = async () =>{ 
      try { 
        let data = await fetch(config.urlAPI + "/api/seach_student_sha1?student_id_sha1=" + id)
         .then(res =>res.json())
        if(!data.message){
          throw Error("No Data Aviable!");
        }else{
          student.value = data.student[0];
        }
        
      } catch (err) { 
        error.value = err.message; 
      } 
    }

    return {student,error,load}
}
export default getStudents