<template>
    <div class="container-bg"></div>
    <div class="container">
        <div class="cont-one">
            <img src="../../assets/logo.png" height="60" alt="">
        </div>
        <div class="cont-two">
        </div>
        <div class="cont-three">
        </div>

        <div class="cont-four">
        </div>
    </div>
    <div v-if="job" class="card profile">
        <!-- <div>{{$route.params.id}}</div> -->

        <div class="img-profile-white"></div>
        <div class="img-profile">
            <img :src="(api+sha1Js(job.image_profile))" alt=""/>
        </div>
        <div class="info">
            <span class="text-label">លេខកូដនិស្សិត</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">{{job.student_id}}</span> 
        </div> 
        <div class="info">
            <span class="text-label">នាម-គោត្តនាម</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">{{job.first_name_km}} {{job.last_name_km}}</span> 
        </div> 
        <div class="info">
            <span class="text-label">អក្សរឡាតាំង</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">{{job.first_name_en}} {{job.last_name_en}}</span> 
        </div>  
        <div class="info">
            <span class="text-label">ជំនាញ</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">
                <span v-if="job.major"> 
                    {{job.major.title_km}}
                </span>
            </span> 
        </div>  
        <div class="info">
            <span class="text-label">ថ្ងៃខែឆ្នាំកំណើត</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">{{getFormattedDate(job.dob)}}</span> 
        </div>
        <div class="info">
            <span class="text-label">លេខទូរសព្ទ</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">0{{job.phone1}}</span> 
        </div>
        <div class="info">
            <span class="text-label">ជំនាន់</span>
            <span class="text-symbol">៖</span>
            <span class="text-value">ទី{{job.generation_id}}</span> 
        </div> 
        <div class="info">
            <span class="text-label">ស្ថានភាព</span>
            <span class="text-symbol">៖</span>
            <span class="text-value color-hl">{{(job.status=1?"កំពុងសិក្សា":"បញ្ចប់សិក្សា")}}</span> 
        </div>
    </div>
</template> 

<script>
import moment from 'moment'

export default {
  name: "JobS",
  props: ["id"],
  data() {
    return {
      job: {},
      api: "https://udms.cadt.edu.kh"
    };
  },
  methods: {
    sha1Js(text) {
      return text;
    },  
        getFormattedDate(value){
         if (value) {
           return moment(String(value)).format('DD-MM-YYYY')
          }
      },
  },
  async mounted() {
    let _THIS = this;

    console.log("log", this.id);
    var ur = _THIS.api + "/api/seach_student_sha1?student_id_sha1=" + this.id;
    console.log("Login:", ur);
    fetch(ur)
      .then(res => res.json())
      .then(
        await function(data) {
          console.log(data.student[0].major.title_km);
          _THIS.job = data.student[0];
        }
      );
    // .catch(err=>console.log(err.message));
  }
};
</script>
<style>
@font-face {
    font-family: "Niradei";
    src: url("../../assets/cadt/Niradei-Regular.ttf");
}
@font-face {
    font-family: "Niradei";
    src: url("../../assets/cadt/Niradei-Bold.ttf");
    font-weight: bold;
}
p, span, label, :not(i) {
    font-family: Niradei !important;
}
.info{
    width: 100%;
    text-align: left;
    margin: 0 auto;
    padding: 20px 30px;
    font-family: Niradei;
    font-size:23px;
}
.text-label{
    width: 150px;
    color: #fff;
    float: left;
}
.text-symbol{
    color: #fff; 
    float: left;
    width: 20px;
}
.text-value{
    color: #fff; 
    float: left; 
    font-weight: bold;
}
label {
  font-size: 18px;
  color: black;
}
h2 {
  color: rgb(248, 124, 0);
}

.container,.container-bg,.cont-two,.card{
    left:0;
    right:0;
    margin-left:auto;
    margin-right:auto;
    width: 500px;
    position: relative;
}
.container{
    height: 820px;
    background:  rgb(248, 124, 0); 
   
}
.container-bg{
    height: 790px;
    background-image:  url(../../assets/bg.png);
    position: absolute;
    z-index: 1;
    opacity:0.1;
}
.cont-one{ 
    height: 80px;
    background:  rgb(248, 124, 0); 
    text-align: left;
    padding: 20px 50px;
  
}
.cont-two{  
    height: 200px;
    background:  #ffffff; 
    
}
.cont-three{  
    height: 470px;
    background: linear-gradient(45deg,rgb(248, 124, 0) 4%, rgb(0, 81, 180) 4%) ;
}
.profile{
    position: absolute !important;
    z-index:4;
    top: 110px;
}
.img-profile{
    background: linear-gradient(45deg,rgb(218, 218, 218) 92%, rgba(0, 81, 180,0) 4%) ;
    width: 250px; 
    height: 330px;
    position: relative;
    margin: 0 auto;
    padding: 0px;
}
.img-profile-white{
    background: linear-gradient(45deg,rgb(255, 255, 255) 92%, rgba(0, 81, 180,0) 4%) ;
    width: 260px;
    height: 340px;
    position: absolute;
    margin: 0 auto;
    padding: 0px;
    z-index:0;
    left:0;
    right:0;
    top:-5px;
    margin-left:auto;
    margin-right:auto;
}
.img-profile img{
    height: 350px;
    margin: 0px;
    bottom: 20px;
    position: relative; 
}
.color-hl{
    color:rgb(91, 227, 0);
}
.cont-four{
    width: 38px;
    height: 37px;
    z-index: 3;   
    background: linear-gradient(45deg,rgb(248, 124, 0) 50%, rgba(0, 81, 180,0) 4%) ;
    top: -37px;
    position: relative;
}
</style>