<template> 
    <div class="about" v-if="students?.length">
      <div v-for="student in students" :key="student.id">
        <router-link v-if="typeof student.student_id_sha1 !== 'undefined'"  :to="{name:'StudnetID',params:{id:student.student_id_sha1}}">
          {{student.first_name_en}} {{student.last_name_en}}
        </router-link>
      </div> 
    </div>
    <div v-else>
      <p>Loading</p>
    </div>
  </template>
<script>
import getStudents from '../../composable/getStudents'
export default {
  name: "JobS",
  data() {
    return {
      jobs: [],
      api: "https://udms.cadt.edu.kh"
    };
  },
  setup() {
      const {students,error,load} = getStudents(); 
      load()
      return {students,error}
  }
}
</script>