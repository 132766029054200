<template>
  <!-- <nav  v-show="$route.name!=='StudnetID'">
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>|
    <router-link to="/jobs">Job</router-link>|
  </nav> -->
  <!-- <button @click="back">BACK</button>
  <button @click="forward">FORWORD</button>
  <button @click="redirect">REDIRECT</button> -->
   <router-view/>
  <div></div>
</template>

<script>
export default{
  methods:{
    back(){

      this.$router.go(-1);
    },
    forward(){
      this.$router.go(1);
    },
    redirect(){
      console.log("okau"); 
      this.$router.push({ name: 'Home' })
    }
  }, 
  watch: {
  $route(to) {
     document.title = `SUDENT - ${to.meta.title}`;
    //  const link = document.querySelector("[rel='icon']")
    //  link.setAttribute('href',to.meta.icon)
  }
}
}
</script>
<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
