import { ref } from "vue"; 
import config from '../../config'
const getStudents = () => {
    const students = ref([]); 
    const error = ref(null); 
    students.value = [1,2,3]
    const load = async () =>{ 
      try { 
        let data = await fetch(config.urlAPI + "/api/student")
         .then(res =>res.json())
        if(!data.message){
          throw Error("No Data Aviable!");
        }else{
          students.value = await data.students;
        //   console.log("DATA:",students.value);
        }
      } catch (err) { 
        error.value = err.message; 
      } 
    }
    return {students,error,load}
}
export default getStudents